<template>
  <v-list dense nav>
    <template v-for="category in categories">
      <v-list-item
        :key="`category-${category.id}`"
        :to="createTargetRoute(category)"
      >
        <!-- category icon -->
        <v-list-item-avatar>
          <category-icon :category="category"></category-icon>
        </v-list-item-avatar>

        <!-- content -->
        <v-list-item-content>
          <!-- category name -->
          <v-list-item-title>{{ category.name }}</v-list-item-title>

          <!-- category Typ eName -->
          <v-list-item-subtitle v-if="visibleCategoryType">{{
            categoryTypeName(category)
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider :key="`category-divider-${category.id}`"></v-divider>
    </template>
  </v-list>
</template>

<script>
// model
import { moduleNames } from "@/model/solution/moduleModel";
import { categoryType, findCategoryType } from "@/model/category/categoryModel";

// router
import { createModuleItemContentRouteLocation } from "@/router/solution/solutionRoutes";

export default {
  name: "CategoryList",
  components: {
    CategoryIcon: () => import("@/components/category/CategoryIcon")
  },
  props: {
    /**
     * @type {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}}[]}
     */
    categories: undefined
  },
  computed: {
    /**
     * visible Category Type only if there are more different category types
     * @return {boolean} true if visible Category Type
     */
    visibleCategoryType() {
      return (
        (this.categories?.filter(c => c.type !== categoryType.general)
          ?.length ?? 0) > 0
      );
    }
  },
  methods: {
    /**
     * create category Target Route
     * @param {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}}} category
     * @return {{name: (String|string), params: {id: (Number|number)}}|undefined}
     */
    createTargetRoute(category) {
      const id = category?.id ?? -1;

      return id >= 0
        ? createModuleItemContentRouteLocation(moduleNames.Category, id)
        : undefined;
    },

    /**
     * display Category Type Name
     * @param {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}}} category
     */
    categoryTypeName: category =>
      `${findCategoryType(category?.categoryType)?.label ?? "?"} Type`
  }
};
</script>
